import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    container,
    title as titleClass,
    description as descriptionClass,
    image,
    ratioClass,
} from './single-story.module.scss';
import { IStory } from '../../models/story.model';

import Title from '../atoms/title';
import Markdown from '../hoc/markdown';

export default function SingleStory({ title, description, media }: IStory) {
    return (
        <div className={container}>
            <Title Tag="h3" className={titleClass}>
                {title}
            </Title>
            <Markdown className={descriptionClass}>{description}</Markdown>
            <Image media={media} className={image} ratioClass={ratioClass} />
        </div>
    );
}
