import React from 'react';

import { IStory } from '../../models/story.model';
import { ISection } from '../../models/section.model';

import Section from '../hoc/section';
import SingleStory from '../molecules/single-story';

import {
    grid,
    title as titleClass,
    subtitle as subtitleClass,
    description as descriptionClass,
    storiesWrapper,
} from './story.module.scss';

export interface IStorySection extends ISection {
    content: ISection['content'] & Record<'stories', IStory[]>;
}

export interface IStoryProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: IStorySection;
}

export default function Story({ section, className, TitleTag }: IStoryProps) {
    const { sectionId, content, css, style } = section;
    const { texts, stories } = content;
    const [title, subtitle, description] = texts;

    return (
        <Section
            sectionId={sectionId}
            className={className}
            classes={{
                container: grid,
                title: titleClass,
                subtitle: subtitleClass,
                description: descriptionClass,
            }}
            title={title}
            subtitle={subtitle}
            description={description}
            TitleTag={TitleTag}
            css={css}
            style={style}
        >
            <div className={storiesWrapper}>
                {stories.map((story, index) => (
                    <SingleStory
                        title={story.title}
                        description={story.description}
                        media={story.media}
                        key={`story-${index}`}
                    />
                ))}
            </div>
        </Section>
    );
}
